import { environment } from '@root/environments/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  enabled: environment.envName !== 'local',
  environment: environment.envName,
  release: `creekmore-marketing-dashboard@${environment.version}`,
  dsn: 'https://4526de519ac326ea0f0d4033df1068c3@o4507028474691584.ingest.us.sentry.io/4507256401297408',
  integrations: [
    Sentry.browserTracingIntegration(),
    //Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/(dev\.)?creekmore\.marketing\/api/, // IDEA: Maybe use the environment.apiUrl in the future
    /^https:\/\/cmmapi.local\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
