import {
  Component,
  ErrorHandler,
  OnInit,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { SidenavService } from '@services/sidenav.service';
import { MessageService } from '@services/message.service';
import { environment } from 'environments/environment';
import { NotificationService } from '@services/notification.service';
import { UserSettingsService } from '@services/user-settings.service';
import { FabComponent } from '@shared/components/fab/fab.component';
import { SearchPopupComponent } from '@shared/components/search-popup/search-popup.component';
import { MessagesComponent } from '@shared/global/messages/messages.component';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { SidebarComponent } from '@shared/global/sidebar/sidebar.component.js';
import { CommonModule } from '@angular/common';
import {
  MatSidenavContainer,
  MatSidenav,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { MenuComponent } from '@shared/global/menu/menu.component';
import { NotificationsComponent } from '@shared/global/notifications/notifications.component';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { fab, fas } from '@awesome.me/kit-62bca205da/icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BrowserDetailsService } from '@services/browser-details.service';
import { UserDialogComponent } from '@shared/components/user-dialog/user-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    MatToolbar,
    NotificationsComponent,
    MatToolbarRow,
    MenuComponent,
    MatSidenavContainer,
    MatSidenav,
    SidebarComponent,
    MatSidenavContent,
    RouterOutlet,
    MessagesComponent,
    SearchPopupComponent,
    FabComponent,
    UserDialogComponent,
  ],
})
export class AppComponent implements OnInit {
  title = environment.name;

  constructor(
    public sidenavService: SidenavService,
    private messageService: MessageService,
    public notificationService: NotificationService,
    public settingsService: UserSettingsService,
    private library: FaIconLibrary,
    private browserService: BrowserDetailsService,
    private router: Router,
  ) {
    this.messageService.add('System: Application Loaded');

    this.library.addIconPacks(fab, fas);

    if (environment.envName !== 'production') {
      this.browserService.setFavicon('assets/icons/favicon-development.png');
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });
  }
}
