import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconName } from '@awesome.me/kit-62bca205da/icons';
import { ConsoleUser } from '@helpers/types/user';
import { UserHelperService } from '@services/user-helper.service';
import { MatIconButton, MatButton } from '@angular/material/button';
import {
  MatCard,
  MatCardHeader,
  MatCardAvatar,
  MatCardTitle,
  MatCardSubtitle,
  MatCardActions,
} from '@angular/material/card';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatTooltip } from '@angular/material/tooltip';

export type UserCardAction = {
  action: string;
  text?: string;
  icon?: IconName;
};

export type UserCardActionEvent = {
  user: ConsoleUser;
  action: string;
};

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  imports: [
    MatTooltip,
    FaIconComponent,
    MatCard,
    MatCardHeader,
    MatCardAvatar,
    MatCardTitle,
    MatCardSubtitle,
    MatCardActions,
    MatIconButton,
    MatButton,
  ],
})
export class UserCardComponent implements OnInit {
  @Input({ required: true }) user!: ConsoleUser;
  @Input() display: 'icon' | 'card' = 'card';
  @Input() cardActions: UserCardAction[] = [];
  @Input() iconTooltipLineTwo: string = '';
  @Input() iconSize: number = 32;
  @Input() isTeamLead: boolean = false;
  @Input() teamName: string = '';
  @Input() showAutoName: boolean = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onCardAction: EventEmitter<UserCardActionEvent> =
    new EventEmitter<UserCardActionEvent>();

  constructor(private userHelperService: UserHelperService) {}

  ngOnInit(): void {
    if (this.iconTooltipLineTwo) {
      this.iconTooltipLineTwo = '\n' + this.iconTooltipLineTwo;
    }

    if (this.isTeamLead) {
      this.iconTooltipLineTwo = '\n' + 'Team Lead';
    }
  }

  showUserDetails(user: ConsoleUser): void {
    this.userHelperService.openUserDialog(user);
  }

  triggerCardAction($event: MouseEvent, user: ConsoleUser, action: string) {
    $event.stopPropagation();

    this.onCardAction.emit({
      user: user,
      action: action,
    });
  }
}
