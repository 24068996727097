import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  isDevMode,
  AfterViewInit,
} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { DialogFormComponent } from '@shared/components/dialog-form/dialog-form.component';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '@services/snackbar.service';
import { ClientSearchService } from '@services/client-search.service';
import { OverlayService } from '@services/overlay.service';
import { AprilFoolsComponent } from '@shared/components/overlay-loader/april-fools/april-fools.component';
import { SpinnerComponent } from '@shared/components/overlay-loader/spinner/spinner.component';
import { PwaService } from '@services/pwa.service';
import { ReleasesService } from '@services/releases.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Release } from '@services/release';
import { environment } from 'environments/environment';
import { BasecampService } from '@services/basecamp.service';
import {
  ThemeSetting,
  UserSettingsService,
} from '@services/user-settings.service';
import { MarkdownPipe } from 'ngx-markdown';
import { MatTooltip } from '@angular/material/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  MatAnchor,
  MatButton,
  MatIconAnchor,
  MatIconButton,
} from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [
    CommonModule,
    MatAnchor,
    RouterLink,
    RouterLinkActive,
    MatButton,
    MatMenuTrigger,
    FaIconComponent,
    MatIconAnchor,
    MatTooltip,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MarkdownPipe,
  ],
})
export class MenuComponent implements OnInit, AfterViewInit {
  name: string = '';

  //API Key
  @ViewChild('apiKeyDialog') apiKeyDialog: TemplateRef<any> | undefined;
  apiKey: string = '';

  //Version
  @ViewChild('releaseNotesDialog') releaseNotesDialog:
    | TemplateRef<any>
    | undefined;

  appVersion = 'v' + environment.version;
  appUpdating = false;
  appUpdateAvailable = false;
  releaseNotes: Release[] = [];

  nexusProfileURL = `${environment.apiUrl}/user/profile`;
  nexusNovaURL = `${environment.apiUrl}/nova`;
  nexusPulseURL = `${environment.apiUrl}/pulse`;
  nexusTelescopeURL = `${environment.apiUrl}/telescope`;

  //April Fools
  showAprilFools = false;

  public basecampLoggedIn = false;

  constructor(
    public auth: AuthService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private clipboard: Clipboard,
    public clientSearch: ClientSearchService,
    private overlayService: OverlayService,
    private pwaService: PwaService,
    private releasesService: ReleasesService,
    public basecampService: BasecampService,
    public settingsService: UserSettingsService,
  ) {}

  ngOnInit() {
    //Version
    this.pwaService.$checkForUpdates().subscribe((updateType) => {
      if (updateType === 'VERSION_DETECTED') {
        this.appUpdating = true;
      } else if (updateType === 'VERSION_READY') {
        this.appUpdateAvailable = true;
      }
    });

    //April Fools
    const today = new Date();
    const aprilFoolsDate = new Date('April 1');
    if (
      today.getDate() === aprilFoolsDate.getDate() &&
      today.getMonth() === aprilFoolsDate.getMonth()
    ) {
      this.showAprilFools = true;
    }

    //Basecamp
    this.basecampService.getBasecampOauthToken().subscribe((token) => {
      this.basecampLoggedIn = !!token;
    });
  }

  ngAfterViewInit(): void {
    this.checkAutomaticShowReleaseNotes();
  }

  /**
   * This is useful for developers to test and troubleshoot
   */
  createToken(): void {
    this.auth.getConsoleToken().subscribe((token) => {
      if (!token) {
        this.snackbarService.openSnackBar({
          message: 'There was an error retriving the API token',
          action: '',
          error: true,
        });

        return;
      }

      this.apiKey = token.token;

      const dialogRef = this.dialog.open(DialogFormComponent, {
        width: '800px',
        data: {
          headerText: 'API Token',
          buttonText: 'Copy',
          template: this.apiKeyDialog,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.clipboard.copy(this.apiKey)) {
            this.snackbarService.openSnackBar({
              message: 'Copied API Token',
              action: '',
            });
          } else {
            this.snackbarService.openSnackBar({
              message: 'Could not be copied',
              action: '',
            });
          }
        }
      });
    });
  }

  showLogs(): void {
    this.settingsService.globalShowLogs = !this.settingsService.globalShowLogs;
  }

  showProfile(): void {
    //this.formTemplateService.open(  );
  }

  /**
   * show release notes if user's latest version doesn't match current version
   */
  private checkAutomaticShowReleaseNotes(): void {
    if (environment.envName === 'local') return;

    if (
      !this.settingsService.globalLatestVersion ||
      this.settingsService.globalLatestVersion !== environment.version
    ) {
      this.showReleaseNotes();
    }
  }

  showReleaseNotes(): void {
    let obsNotes: Observable<Release[]>;

    if (this.releaseNotes.length > 0) {
      obsNotes = of(this.releaseNotes);
    } else {
      let mode: 'beta' | 'live' = isDevMode() ? 'beta' : 'live';
      obsNotes = this.releasesService
        .getAllReleaseNotes(mode)
        .pipe(tap((notes) => (this.releaseNotes = notes)));
    }

    obsNotes.subscribe((releases) => {
      if (!releases) {
        return;
      }

      const dialogRef = this.dialog.open(DialogFormComponent, {
        width: '800px',
        data: {
          headerText: 'Release Notes',
          template: this.releaseNotesDialog,
          buttonText: 'Mark as Read',
          cancelText: 'Close',
        },
      });

      dialogRef.afterClosed().subscribe((markedRead) => {
        if (markedRead) {
          this.settingsService.globalLatestVersion = environment.version;
        }
      });
    });
  }

  refreshApp(): void {
    window.location.reload();
  }

  showAprilFoolsDialog() {
    const deletingClose = this.overlayService.open({
      component: SpinnerComponent,
      config: {
        data: {
          spinnerText: 'Deleting all Creekmore Marketing data',
          spinnerTextConsoleParts: [
            ['Exclusivity Map', ...Array(20).fill('.'), 'DELETED'],
            ['Database', ...Array(27).fill('.'), 'DELETED'],
            ['CMM Consoles', ...Array(23).fill('.'), 'DELETED'],
            ['Client Basecamps', ...Array(19).fill('.'), 'DELETED'],
            ['Notifying Executive Leadership', ...Array(8).fill('.'), 'DONE'],
          ],
        },
      },
      afterClose: () => {
        const aprilFoolsClose = this.overlayService.open({
          component: AprilFoolsComponent,
          disableClose: false,
        });
      },
    });
    setTimeout(() => {
      this.overlayService.close(deletingClose);
    }, 10000);
  }

  public setTheme(theme: ThemeSetting) {
    this.settingsService.globalTheme = theme;
    this.settingsService.setTheme(theme);
  }
}
