import { marked } from 'marked';
import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const options: MarkedOptions = {
    gfm: true,
    breaks: false,
    pedantic: false,
  };

  const linkRenderer = renderer.link;
  renderer.link = (link) => {
    renderer.parser = new marked.Parser(options);
    const html = linkRenderer.call(renderer, link);
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
  };

  return {
    ...options,
    renderer: renderer,
  };
}
