import * as MonacoType from 'monaco-editor';
import * as MonokaiTheme from '@assets/themes/Monokai.json';
import { NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';

declare var monaco: typeof MonacoType;

export const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad: () => {
    monaco.editor.defineTheme('monokai', (MonokaiTheme as any).default);

    if (document.documentElement.classList.contains('dark')) {
      monaco.editor.setTheme('monokai');
    } else {
      monaco.editor.setTheme('vs');
    }

    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
    });

    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true, //FIXME: Set to false, but do code cleanup
      noSyntaxValidation: false,
    });
  },
};
