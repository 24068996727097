import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FabService } from '@services/fab.service';

@Component({
  selector: 'app-fab',
  imports: [CommonModule, MatButtonModule, MatTooltipModule, FontAwesomeModule],
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent {
  constructor(public fabService: FabService) {}
}
