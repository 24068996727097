import {
  ApplicationConfig,
  EnvironmentProviders,
  ErrorHandler,
  inject,
  provideAppInitializer,
  Provider,
  importProvidersFrom,
} from '@angular/core';
import { Router } from '@angular/router';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';

import { environment } from '@root/environments/environment';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { provideServiceWorker } from '@angular/service-worker';
import { RemoteConfigSettings } from '@services/firebase-remote-config.service';
import {
  provideRemoteConfig,
  getRemoteConfig,
} from '@angular/fire/remote-config';
import { setAnalyticsCollectionEnabled } from '@firebase/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import {
  provideNgxWebstorage,
  withLocalStorage,
  withNgxWebstorageConfig,
} from 'ngx-webstorage';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { autoLoginInterceptor } from './interceptors/auto-login.interceptor';
import {
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import {
  UserTrackingService,
  provideAnalytics,
  getAnalytics,
} from '@angular/fire/analytics';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideRouter } from '@angular/router';
import { ROUTES } from './routes';

import { monacoConfig } from './app-config-setup/monaco.config';
import { markedOptionsFactory } from './app-config-setup/markdown.config';

// setup Sentry
import * as Sentry from '@sentry/angular';
import './app-config-setup/sentry.config';

function generateProvidersArray(
  localProviders: Provider[],
  otherProviders: (Provider | EnvironmentProviders)[],
) {
  if (environment.envName === 'local') {
    return localProviders.concat(otherProviders);
  } else {
    return otherProviders;
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    /**
     * ============================
     *
     * Old Module Providers
     *
     * ============================
     */
    importProvidersFrom([
      BrowserModule,
      CommonModule,
      FormsModule,
      MatMenuModule,
      MatToolbarModule,
      MatSidenavModule,
      MatButtonModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      MatTableModule,
      MatSortModule,
      MatExpansionModule,
      MatSnackBarModule,
      MatBadgeModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      ClipboardModule,
      FontAwesomeModule,
      NgxPicaModule,
      MonacoEditorModule.forRoot(monacoConfig),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      }),
    ]),

    /**
     * ============================
     *
     * Regular Providers
     *
     * ============================
     */
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.envName === 'local') {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    provideAnalytics(() => {
      const analytics = getAnalytics();
      if (environment.envName === 'local') {
        setAnalyticsCollectionEnabled(analytics, false);
      }
      return analytics;
    }),
    provideRemoteConfig(() => {
      const remoteConfig = getRemoteConfig();
      if (environment.envName === 'local') {
        remoteConfig.settings.minimumFetchIntervalMillis = 10000;
        remoteConfig.defaultConfig = {
          playNotificationAudio: true,
        } as RemoteConfigSettings;
      }
      return remoteConfig;
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ...generateProvidersArray(
      [],
      [
        MatDatepickerModule,
        UserTrackingService,
        provideHttpClient(withInterceptors([autoLoginInterceptor])),
      ],
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideRouter(ROUTES),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'cmm-console' }),
      withLocalStorage(),
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
  ],
};
