import { Component } from '@angular/core';
import { SidenavService } from '@services/sidenav.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [
    CommonModule,
    MatAnchor,
    RouterLinkActive,
    RouterLink,
    MatTooltip,
    FaIconComponent,
    MatIconButton,
  ],
})
export class SidebarComponent {
  constructor(public sidenavService: SidenavService) {}
}
