import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CmmApiService } from './helpers/cmm-api.service';
import { Release } from './release';
import { environment } from '@root/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReleasesService extends CmmApiService {
  protected override serviceName = 'ReleasesService';

  private releaseNotesURL = CmmApiService.API_URL + '/github/release-notes';

  /** GET release notes from the server */
  getReleaseNotes(version: string): Observable<Release | null> {
    const url = `${this.releaseNotesURL}/${version}?project=${environment.projectName}`;

    return this.http.get<Release>(url, this.httpOptions).pipe(
      tap((_) => this.log('fetched release notes')),
      catchError(
        this.handleError<Release>(`getReleaseNotes version=${version}`),
      ),
      this.trace('getReleaseNotes'),
    );
  }

  /** GET release notes from the server */
  getAllReleaseNotes(mode: 'beta' | 'live'): Observable<Release[]> {
    const url = `${this.releaseNotesURL}/all/${mode}?project=${environment.projectName}&limit=10`;

    return this.http.get<Release[]>(url, this.httpOptions).pipe(
      tap((_) => this.log('fetched all release notes')),
      catchError(
        this.handleError<Release[]>(`getAllReleaseNotes mode=${mode}`),
      ),
      map((notes) => notes ?? []),
      this.trace('getAllReleaseNotes'),
    );
  }
}
